.mmp-main-logo {
  width: 130px !important;
  margin-right: 0px;
  margin-left: 5px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.mmp-main-sq-logo {
  display: block;
  min-width: 35px;
  min-height: 48px;
  max-width: 35px;
  max-height: 48px;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-left: 10px;
}

.mmp-menu-item {
  line-height: 26px !important;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.mmp-profile-card .label {
  padding: 5px !important;
}

.mmp-floatleft {
  display: block;
  float: left;
}

.mmp-floatright {
  display: block;
  float: right;
}

.mmp-clearfix {
  clear: both;
}

.mmp-profile-card-details {
  padding-left: 10px;
}

.mmp-profile-counts {
  padding-top: 5px;
  padding-bottom: 5px;
}

.mmp-main-content {
  padding-top: 50px;
  background-color: #f7f7f7;
}

.mmp-app-icon {
  height: 40px;
  margin: 0.25em;
}

.mmp-site-copyright {
  display: flex;
  justify-content: center;
  flex: 1;
}

.mmp-profile-fullname {
  max-width: 100px;
}

.mmp-profile-firstname {
  margin-right: 3px;
}

.mmp-filters-header {
}

.mmp-page-segment-container {
  margin-top: 8px;
  margin-bottom: 40px;
}

.mmp-page-segment {
  min-height: 400px;
}

.mmp-margin-right {
  margin-right: 10px !important;
}

.mmp-margin-left {
  margin-left: 10px !important;
}

.mmp-margin-bottom {
  margin-bottom: 5px;
}

.mmp-margin-top {
  margin-top: 5px;
}

.mmp-loading-more-narrow {
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 1;
}

.mmp-loading-more {
  margin-top: 80px;
  margin-bottom: 80px;
  z-index: 1;
}

.mmp-loading-more .ui.active.centered.inline.loader,
.mmp-loading-more-narrow .ui.active.centered.inline.loader {
  z-index: 1;
}

.mmp-loading-more-hundredpercent {
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
}

.mmp-photo-mosaic {
  margin: 2px;
}

.mmp-blurred-image-mask {
  filter: blur(10px);
}

.mmp-blur-hidden-overflow {
  overflow: "hidden";
}

.mmp-mosaic-image {
  padding-right: 4px;
  cursor: pointer;
}

.mmp-mosaic-private-image {
  padding-right: 4px;
  position: "relative";
  cursor: pointer;
}

.mmp-top-filters-bar {
  height: 38px;
  z-index: 1000;
  margin-top: 10px;
}

.mmp-mobile-menu {
  margin: 0px !important;
}

.ui.push.sidebar,
.ui.inverted.top.fixed.mmp-mobile-menu.mmp-blue-menu.menu,
.mmp-top-blue-menu-container {
  z-index: 105 !important;
}

.ui.inverted.top.fixed.mmp-mobile-menu.mmp-blue-menu.menu {
  height: 52px !important;
}

@media (max-width: 767px) {
  #mmp-mobile-content .mmp-main-content div > .sixteen.wide.column {
    padding-top: 0px !important;
  }
}

.mmp-squad-photos-loading-more {
  min-height: calc(var(--vh, 1vh) * 100 - 222px);
}

.mmp-no-squad-photos {
  min-height: calc(var(--vh, 1vh) * 100 - 222px);
}

.mmp-group-photos {
  padding-top: 4px !important;
  padding-left: 4px !important;
}

.mmp-photos-scroller {
  padding-top: 8px;
}

.mmp-location-search input {
  border-radius: 0.28571429rem !important;
}

.mmp-tag-search input {
  border-radius: 0.28571429rem !important;
  width: 180px;
}

.mmp-tag-search-addphoto {
  border-radius: 0.28571429rem !important;
  width: 110px;
}

.mmp-tag-search-editphoto {
  border-radius: 0.28571429rem !important;
  width: 160px !important;
}

.mmp-search-photo-labels {
  line-height: 38px;
}

.mmp-search-photo-dropdown-radius {
  min-width: 100px !important;
  width: 100px !important;
}

.mmp-fullscreen-map {
  min-height: calc(var(--vh, 1vh) * 100 - 136px);
  width: 100%;
  z-index: 1;
  position: relative;
  top: 5px;
}

.mmp-squad-map {
  min-height: calc(var(--vh, 1vh) * 100 - 228px);
}

.mmp-centered-ele {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: -16px !important;
  margin-left: -16px !important;
}

.mmp-nearby-filters {
  width: 280px;
  margin: 0px auto;
}

.pinPopup_outer {
  width: 155px;
  height: 194px;
}

.pinPopup_outer_photocollection {
  width: 155px;
  height: 214px;
}

.grid-item.themapgriditem {
  width: 100%;
}

.leaflet-container {
  background-color: rgba(255, 0, 0, 0);
}

@media (max-width: 1080px) {
  .mmp-drop-box-heading-desktop {
    display: none;
  }

  .mmp-uploadphotos-floating-dropbox {
    top: 47px !important;
  }

  .mmp-app-collection-photos-container .mmp-uploadphotos-floating-dropbox {
    top: unset !important;
  }

  .mmp-app-collection-photos-container .mmp-drop-box-heading-desktop {
    display: block;
  }

  .mmp-drop-box-heading-mobile {
    display: block;
    margin-top: 0px;
  }

  .mmp-desktop-menu {
    display: none;
  }

  .mmp-mobile-menu {
    display: block;
  }

  .mmp-useraccountwidget-outer {
    height: 36px !important;
  }

  .mmp-photo-import-actions {
    height: 126px !important;
    flex-direction: column !important;
  }

  .mmp-photo-actions-uploading {
    height: 90px !important;
    flex-direction: column !important;
  }
}

@media (min-width: 1080px) {
  .mmp-drop-box-heading-desktop {
    display: block;
  }

  .mmp-drop-box-heading-mobile {
    display: none;
  }

  .mmp-desktop-menu {
    display: block;
  }

  .mmp-mobile-menu {
    display: none;
  }
}

@media (max-width: 1080px) {
  .mmp-analytics-photodetails {
    display: none !important;
  }
}

@media (min-width: 1080px) {
  .mmp-analytics-photodetails {
    display: block !important;
  }
}

.mmp-desktop-menu .mmp-menu-item,
.mmp-mobile-menu .mmp-menu-item {
  cursor: pointer;
}

.mmp-blue-menu {
  background-color: #428bca !important;
}

.mmp-desktop-menu .sidebar.menu,
.mmp-mobile-menu .sidebar.menu {
  background-color: #1b1c1d !important;
}

.mmp-desktop-menu .ui.inverted.pointing.menu .active.item:after {
  display: none;
}

.mmp-slate-text {
  color: #1b1c1d !important;
}

.mmp-main-sq-logo-login {
  display: block;
  position: relative;
  top: -5px;
  width: 50px !important;
  height: 50px !important;
}

.mmp-background-blue {
  background-color: #428bca !important;
  color: #ffffff !important;
}

.mmp-bullet-divider {
  margin-left: 15px;
  margin-right: 15px;
}

.mmp-recaptcha {
  margin-bottom: 12px;
  height: 82px;
}

.mmp-error-message {
  text-align: left !important;
}

.mmp-useraccountwidget-username {
  margin-right: 10px;
  color: #ffffff;
}

.mmp-useraccountwidget-outer-sidemenu {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mmp-profile-menu {
  color: #ffffff;
}

.mmp-profile-menu {
  width: 200px;
  margin-top: 6px !important;
}

.mmp-profile-widget-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px !important;
  border: solid white 1px;
}

.mmp-profile-newsfeed-icon {
  width: 60px !important;
  height: 60px !important;
  border-radius: 30px !important;
}

.mmp-profile-newsfeed-icon-smaller {
  width: 36px !important;
  height: 36px !important;
  border-radius: 18px !important;
}

.mmp-profile-newsfeed-icon-sq {
  width: 60px !important;
  height: 60px !important;
}

.mmp-rhs-widget {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 5px !important;
  padding-left: 7px !important;
}

.mmp-rhs-widget {
  height: 48px;
}

.mmp-profile-card {
  max-width: 490px !important;
  width: 100% !important;
  margin: 0px auto !important;
}

.mmp-profile-card .mmp-avatar {
  width: 90px !important;
  height: 90px !important;
  border-radius: 45px !important;
  border: solid 2px #428bca;
  cursor: pointer;
}

.mmp-meta-profile {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mmp-profile-card .mmp-editprofile-pic-widget {
  position: absolute;
  z-index: 1;
  left: 75px;
}

.mmp-useraccountwidget-outer .mmp-editprofile-pic-widget {
  position: absolute;
  right: 4px;
  top: 4px;
}

.mmp-useraccountwidget-outer .mmp-profile-widget-icon {
  cursor: pointer;
}

body {
  overflow-y: hidden;
}

#scroll-root {
  overflow-y: auto !important;
  height: calc(var(--vh, 1vh) * 100);
}

.mmp-account-lineheight {
  line-height: 35px;
}

.mmp-drop-box {
  background: #f8f8f8;
  border: 5px dashed #ddd;
  width: 100%;
  height: 200px;
  text-align: center;
  padding-top: 25px;
  margin: 0px;
}

.mmp-profile-pic-preview {
  max-width: 150px;
  max-height: 150px;
}

.mmp-pointer-cursor {
  cursor: pointer;
}

.mmp-orientation-6 {
  transform: rotate(90deg);
}

.mmp-orientation-8 {
  transform: rotate(270deg);
}

.mmp-orientation-3 {
  transform: rotate(180deg);
}

.mmp-photo-info {
  margin-top: 20px;
}

img.mmp-image-cropper {
  object-fit: cover;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  border: solid 2px #428bca;
}

img.mmp-image-socialimport-profile {
  object-fit: cover;
  height: 50px;
  width: 50px;
}

img.mmp-image-import-preview {
  object-fit: cover;
  height: 150px;
  width: 150px;
  padding: 5px;
}

.mmp-profile-pic-preview-container {
  height: 150px;
  width: 150px;
  margin: 0px auto;
}

.mmp-email-sent {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.mmp-passwd-warning {
  margin-bottom: 5px !important;
  margin-right: 5px !important;
  display: block !important;
  float: left !important;
}

.mmp-passwd-success {
  margin-bottom: 5px;
}

.mmp-list-passwd-errors {
  min-height: 20px;
  margin: 10px;
}

#tandcs-chronosheets-container {
  font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
}

#tandcs-chronosheets h2 {
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
  padding-top: 21px;
}

#tandcs-chronosheets h1,
#tandcs-chronosheets h2,
#tandcs-chronosheets h3 {
  color: #3f3f3f;
  margin: 0;
}

#tandcs-chronosheets p {
  color: #6b6b6b;
}

.mmp-fullscreen-photo {
  max-height: calc(var(--vh, 1vh) * 100 - 160px);
  max-width: calc(100vw - 80px);
}

.mmp-photo-slider-chevron-container,
.mmp-photo-slider-chevron-container div {
  width: 30px;
  height: 30px;
}

.mmp-photo-slider-chevron-container img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.mmp-photo-with-slider {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(var(--vh, 1vh) * 100 - 120px) !important;
  justify-content: center;
  align-items: center;
}

.mmp-photo-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.mmp-photo-modal {
  display: flex;
  flex: 1;
  width: calc(100vw - 60px);
}

.mmp-profile-widget-lightbox-username {
  color: #ffffff;
  font-size: 16px;
  line-height: 17.5px;
}

.mmp-profile-widget-lightbox-darkmode-username {
  color: #000000;
  font-size: 16px;
  line-height: 17.5px;
}

.mmp-photo-modal .mmp-profile-card {
  max-width: 100% !important;
}

.mmp-photo-map-thmb {
  z-index: 500;
  cursor: pointer !important;
  position: relative;
  top: 8px;
}

.mmp-profile-subtitle {
  color: #aaaaaa;
}

.mmp-photo-modal-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.mmp-action-modal-btn {
  cursor: pointer;
  margin-left: 10px !important;
}

.mmp-desktop-menu .ui.secondary.pointing.menu .active.item {
  font-weight: normal;
}

.mmp-import-card-centered {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-content: center;
  align-items: center;
}

.mmp-photo-import-grid {
  padding-top: 30px !important;
}

.mmp-photo-to-add {
  border: solid 1px black;
}

.mmp-photo-import-controls {
  padding: 5px;
}

.mmp-mark-for-import-toggle {
  margin-top: 5px;
  margin-left: 5px;
}

.mmp-mark-for-import-toggle-label {
  padding-left: 10px;
  position: relative;
  bottom: 7px;
}

.mmp-photo-selector-controls {
  display: flex !important;
  flex-direction: "row" !important;
  padding-right: 5px !important;
}

.mmp-photo-selector-img-preview {
  width: 160px;
  height: 160px;
}

.mmp-form-field-label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.mmp-noncleared-ele {
  clear: none !important;
}

.mmp-mini-map-container {
  background-color: #ffffff;
  max-width: 100%;
  height: 200px;
  border-radius: 20px;
  z-index: 2;
}

.mmp-map-container {
  background-color: #ffffff;
  max-width: 100%;
  max-height: 400px;
  height: calc(var(--vh, 1vh) * 100 - 140px);
  z-index: 2;
}

.mmp-comments-container {
  background-color: #ffffff;
  max-width: 100%;
  max-height: 680px;
  height: calc(var(--vh, 1vh) * 100 - 140px);
  color: #000 !important;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.mmp-editphoto-container {
  max-width: 100%;
  max-height: 600px;
  height: calc(var(--vh, 1vh) * 100 - 140px);
}

.mmp-map-modal .mmp-action-modal-btn,
.mmp-comments-modal .mmp-action-modal-btn,
.mmp-newsfeeditem-modal .mmp-action-modal-btn {
  height: 30px;
  float: right;
  right: -7px;
  position: relative;
}

.mmp-save-location-btn {
  height: 60px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mmp-photo-import-actions {
  width: 100%;
  height: 90px;
  background-color: rgba(66, 139, 202, 0.8);
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.mmp-photo-actions-uploading {
  justify-content: flex-end;
}

.mmp-photo-import-details {
  margin-right: 20px;
  font-size: 16px;
  line-height: 60px;
  color: #fff;
}

.mmp-photo-import-do-import {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-social-profile-fullname {
  font-size: 16px;
  color: #ffffff;
  line-height: 50px;
  margin-left: 10px;
}

.mmp-social-profile-fullname-inverted {
  font-size: 16px;
  color: #000000;
  line-height: 50px;
  margin-left: 10px;
}

.mmp-image-socialimport-type {
  object-fit: cover;
  height: 25px;
  width: 25px;
  position: relative;
  right: -15px;
}

.mmp-social-profile-inverted {
  width: 300px;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 20px;
}

.mmp-no-results-found {
  flex: 1;
  display: flex;
  padding: 50px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.mmp-loader-results {
  padding: 50px !important;
}

.mmp-analytics-flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mmp-analytics-filters {
}

.mmp-analytics-pop-photos {
  min-height: 100px;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
}

.mmp-analytics-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 25px;
}

.mmp-analytics-photodetails {
  width: 500px;
  padding: 5px;
}

.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 240px;
}

.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

.DayPickerInput input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  width: 120px;
}

.mmp-daterange-spacer {
  padding-right: 4px;
  padding-left: 4px;
}

.InputFromTo {
  width: 330px;
  margin: 0px auto;
}

.mmp-photo-for-stats img {
  width: 90px;
  height: 90px;
  margin: 5px;
  cursor: pointer;
}

.mmp-photo-for-stats {
  display: flex !important;
  flex: 1;
  justify-content: center;
  align-content: center;
}

.mmp-analytics-pop-photos button::before {
  color: #000000 !important;
}

.mmp-analytics-pop-photos-title {
  padding: 5px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mmp-analytics-pop-photos-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.mmp-analytics-pop-photos-description p {
  max-width: 256px;
  text-align: center;
}

.mmp-analytics-photodetails img {
  /* max-width: 480px;
	height: auto; */
}

.mmp-analytics-photodetails-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mmp-selectedphoto-account-widget {
  padding: 4px;
}

.mmp-analytics-photo-charts {
  padding: 4px;
  display: flex;
  flex: 1;
  height: 400px;
}

.mmp-stats-summary {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-newsFeedOuter {
  margin-top: 10px;
  margin-bottom: 0px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 12px;
}

.mmp-newsFeedAvatarSmaller {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  overflow: "hidden";
  margin-right: 10px;
  cursor: pointer;
}

.mmp-newsFeedAvatar {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: "hidden";
  margin-right: 10px;
  cursor: pointer;
}

.mmp-newsFeedAvatar-following {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: "hidden";
  margin-right: 10px;
  cursor: pointer;
  display: block;
  float: left;
}

.mmp-avatar-carousel {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  overflow: "hidden";
  cursor: pointer;
  display: block;
  animation: none;
}

@keyframes growavatar {
  from {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }
  to {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
}

@keyframes shrinkavatar {
  from {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  to {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }
}

@keyframes growaward {
  from {
    width: 80px;
    height: 80px;
  }
  to {
    width: 105px;
    height: 105px;
  }
}

@keyframes shrinkaward {
  from {
    width: 105px;
    height: 105px;
  }
  to {
    width: 80px;
    height: 80px;
  }
}

.slick-current + .slick-active .mmp-avatar-carousel {
  animation: growavatar 0.5s ease-in-out forwards;
}

.mmp-avatar-carousel:not(.slick-current + .slick-active .mmp-avatar-carousel) {
  animation: shrinkavatar 0.5s ease-in-out forwards;
}

.mmp-award-newsfeed-item .slick-current + .slick-active .mmp-award-picture img {
  animation: growaward 0.5s ease-in-out forwards;
}

.mmp-award-newsfeed-item
  .mmp-award-picture
  img:not(.slick-current + .slick-active .mmp-award-picture img) {
  animation: shrinkaward 0.5s ease-in-out forwards;
}

.mmp-award-newsfeed-item.mmp-achived-awards .slick-slider {
  height: 220px;
}

.mmp-profileIcon {
  width: 60px;
  height: 60px;
}

.mmp-dateSubHeading {
  color: #d3d3d3 !important;
  font-size: 12px;
}

.mmp-thumbnailList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
}

.mmp-photoThumbnail {
  width: 75px;
  height: 75px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.mmp-photoThumbnail-alt {
  width: 75px;
  height: 75px;
  cursor: pointer;
}

.mmp-private-photoThumbnail {
  width: 75px;
  height: 75px;
  margin-top: 5px;
  cursor: pointer;
  margin-right: -11px;
}

.mmp-edit-comment-widget {
  opacity: 0.3;
  font-size: 12px;
  cursor: pointer;
}

.mmp-privacy-settings-section-header {
  font-size: 14px;
}

.mmp-edit-photo-privacy {
  padding-left: 12px;
}

.mmp-caption-with-privacy {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mmp-locationList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  border-left: solid #f2f2f2 2.5px;
  margin-top: 5px;
  padding-left: 5px;
}

.mmp-locationList-opposite {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  border-right: solid #f2f2f2 2.5px;
  margin-top: 5px;
  padding-right: 5px;
}

.mmp-cat-tag-cloud {
  width: 200px;
}

.mmp-profile-on-newsfeed {
  margin-top: 10px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.ui.card.mmp-profile-on-newsfeed {
  border-radius: 12px !important;
}

.mmp-following-widget {
  width: 100%;
  height: 250px;
  overflow-y: hidden;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 0px;
  border-radius: 12px;
}

.mmp-following-widget-inner {
  width: 100%;
  height: 215px;
  overflow-y: auto;
}

.mmp-profile-following-widget {
  margin: 10px;
}

.mmp-following-widget-header {
  height: 30px;
  font-weight: 700;
  font-size: 1.28571429em;
  margin-top: -0.21425em;
  line-height: 1.28571429em;
  display: block;
  color: rgba(0, 0, 0, 0.85);
}

.mmp-newsfeed-sticky .ui.sticky {
  z-index: 0 !important;
}

.mmp-no-news-msg {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mmp-no-news-msg p {
  max-width: 256px;
  text-align: center;
}

#mmp-success-message-note-creator {
  margin-bottom: 1rem !important;
}

.mmp-note-creator-form .row {
  padding-bottom: 0px !important;
}

.mmp-file-upload-section {
  padding-top: 105px;
}

.mmp-file-upload-section.mmp-app-photoupload-modal {
  padding: 5px;
  margin-bottom: 45px;
  min-height: 130px;
  max-height: 410px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mmp-upload-photos-controls {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 4px;
  margin-top: 6px;
}

.mmp-toast-message {
  text-align: center;
  color: #db2828 !important;
}

.mmp-toast-message-notif-only {
  text-align: center;
  color: #000000 !important;
}

.mmp-uploadphotos-floating-dropbox {
  width: 100%;
  height: 92px;
  background-color: rgba(66, 139, 202, 0.8);
  position: fixed;
  top: 52px;
  left: 0px;
  padding: 10px;
  align-items: center;
  z-index: 3;
}

.mmp-drop-box-uploadphotos {
  background: #f8f8f8;
  border: 5px dashed #ddd;
  width: 100%;
  height: 70px;
  text-align: center;
  padding-top: 15px;
  margin: 0px;
}

.mmp-upload-photos-filename {
  padding-left: 4px;
}

.mmp-fileupload-preview {
  display: flex !important;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.mmp-fileupload-preview-cell {
  display: block;
  float: left;
  padding-bottom: 20px;
  height: 200px;
}

@media (min-width: 0px) and (max-width: 500px) {
  .mmp-fileupload-preview-cell {
    width: 50%;
  }

  .mmp-typist {
    font-size: 15px !important;
    line-height: 25px !important;
    height: 25px !important;
  }

  .mmp-photo-selector-controls img.mmp-image-import-preview {
    width: 100px;
    height: 100px;
  }

  .mmp-photo-selector-controls .mmp-photo-selector-img-preview {
    width: 110px;
    height: 110px;
    padding: 5px !important;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .mmp-fileupload-preview-cell {
    width: 33.333%;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .mmp-fileupload-preview-cell {
    width: 25%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .mmp-fileupload-preview-cell {
    width: 20%;
  }
}

@media (min-width: 1600px) {
  .mmp-fileupload-preview-cell {
    width: 20%;
  }
}

@media (min-width: 0px) and (max-width: 350px) {
  .mmp-home-logo-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mmp-home-typist-container {
    padding-bottom: 10px;
  }
}

@media (min-width: 350px) and (max-width: 500px) {
  .mmp-home-logo-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mmp-home-typist-container {
    padding-bottom: 20px;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .mmp-home-logo-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mmp-home-typist-container {
    padding-bottom: 30px;
  }
}

@media (min-width: 800px) {
  .mmp-home-logo-container {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .mmp-home-typist-container {
    padding-bottom: 35px;
  }
}

.mmp-home-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mmp-home-typist-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mmp-queued-photo {
  color: #cccccc;
}

.grad {
  background: rgb(255, 255, 255);
  background: -moz-radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(66, 139, 202, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(66, 139, 202, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(66, 139, 202, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#428bca", GradientType=1);
  width: 100%;
  padding: 0px;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 1000px;
}

.mmp-typist {
  font-size: 40px;
  line-height: 55px !important;
  height: 55px !important;
  color: #000000;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.mmp-slider-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.mmp-slider-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s ease-out;
}

@media (min-width: 0px) and (max-width: 350px) {
  .mmp-home-logo {
    width: 260px;
  }

  .mmp-typist {
    font-size: 20px !important;
    line-height: 35px !important;
    height: 35px !important;
  }

  .mmp-home-login {
    width: 80px !important;
    height: 25px !important;
    margin: 0em !important;
    font-size: 9px !important;
    background-color: #428bca !important;
  }

  .mmp-mobile-menu.mmp-blue-menu .ui.inverted.button {
    padding: 8px;
  }

  .mmp-signup-card {
    width: 90% !important;
  }
}

@media (min-width: 350px) and (max-width: 500px) {
  .mmp-home-logo {
    width: 300px;
  }

  .mmp-typist {
    font-size: 20px !important;
    line-height: 35px !important;
    height: 35px !important;
  }

  .mmp-home-login {
    width: 96px !important;
    height: 30px !important;
    margin: 0.25em !important;
    font-size: 12px !important;
    background-color: #428bca !important;
  }

  .mmp-mobile-menu.mmp-blue-menu .ui.inverted.button {
    padding: 8px;
  }

  .mmp-signup-card {
    width: 90% !important;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .mmp-home-logo {
    width: 400px;
  }

  .mmp-typist {
    font-size: 30px !important;
    line-height: 45px !important;
    height: 45px !important;
  }

  .mmp-home-login {
    width: 112px !important;
    height: 35px !important;
    font-size: 14px !important;
    margin: 0.25em !important;
    background-color: #428bca !important;
  }

  .mmp-signup-card {
    width: 420px !important;
  }
}

@media (min-width: 800px) {
  .mmp-home-logo {
    width: 400px;
  }

  .mmp-typist {
    font-size: 40px !important;
    line-height: 55px !important;
    height: 55px !important;
  }

  .mmp-app-icon.mmp-home-screen {
    height: 50px !important;
  }

  .mmp-home-login {
    width: 160px !important;
    height: 50px !important;
    margin: 0.25em !important;
    font-size: 18px !important;
    background-color: #428bca !important;
  }

  .mmp-signup-card {
    width: 420px !important;
  }
}

.mmp-home-login {
  margin-bottom: 0.5em !important;
}

.mmp-mobile-menu.mmp-blue-menu.menu {
  height: 48px !important;
}

.mmp-mobile-menu.mmp-blue-menu.menu .item::before {
  width: 0px !important;
}

.mmp-progressive-photo-actionbar {
  height: 38px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  top: 43px;
  position: relative;
  left: 5px;
  margin-top: -38px;
  z-index: 2;
  width: 115px !important;
}

.DayPickerInput-Overlay {
  z-index: 5 !important;
}

.mmp-progressive-photo-actionbar button {
  background-color: #00000090 !important;
  color: #ffffff75 !important;
}

.mmp-progressive-photo-actionbar button:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.mmp-progressive-private-photo {
  width: 25px;
  height: 25px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  width: 100%;
  top: 30px;
  position: relative;
  right: 5px;
  margin-top: -25px;
  z-index: 1;
}

.mmp-modal-content .mmp-mosaic-private-image {
  line-height: 0px;
  padding: 0px !important;
}

.mmp-progressive-private-photo img {
  width: 25px;
  height: 25px;
}

.mmp-photo-trek-privacy-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mmp-photo-trek-privacy-title .mmp-private-trek-collection {
  margin-right: 6px;
  line-height: 0px !important;
}

.mmp-private-collection-checkbox .mmp-private-trek-collection {
  margin-right: 6px;
}

.mmp-private-collection-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mmp-private-trek-collection {
  width: 16px;
  height: 16px;
}

.mmp-private-trek-collection img {
  width: 16px;
  height: 16px;
}

.mmp-newsfeed-private-details {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  top: 4px;
  position: relative;
}

.mmp-newsfeed-private-details img {
  width: 16px;
  height: 16px;
}

.mmp-caption-with-privacy h2 {
  margin-top: 0px !important;
}

.mmp-newsfeed-private-group {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.mmp-newsfeed-private-group img {
  width: 20px;
  height: 20px;
}

.mmp-newsfeed-private-video {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.mmp-newsfeed-private-video img {
  width: 16px;
  height: 16px;
}

.mmp-newsfeed-private-photo {
  width: 16px;
  height: 16px;
  top: 7px;
  position: relative;
  right: 8px;
}

.mmp-newsfeed-private-photo img {
  width: 16px;
  height: 16px;
}

.mmp-usergallery-container {
  display: flex;
  flex: 1;
  height: calc(var(--vh, 1vh) * 100 - 221px);
  flex-direction: row;
  width: 100%;
  z-index: 1;
  position: relative;
  background-color: #ffffff;
}

.mmp-usergallery-map {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.mmp-app-collections-map-inner {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  z-index: 0;
}

.mmp-photo-details-min-height {
  min-height: 44px;
}

.mmp-usergallery-photodetails {
  overflow-y: auto;
  background-color: #fff;
}

@media (min-width: 0px) and (max-width: 1080px) {
  .mmp-newsfeed-private-group {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .mmp-newsfeed-private-group img {
    width: 16px;
    height: 16px;
  }

  .mmp-group-privacy-label {
    bottom: 1px !important;
  }
  .mmp-squad-screen-container {
    padding-top: 6px;
    padding-bottom: 10px;
  }
  .mmp-usergallery-photodetails {
    overflow-y: hidden;
  }

  .mmp-usergallery-container {
    flex-direction: column;
  }

  .mmp-usergallery-largemap-mobileonly {
    display: block;
    flex: unset;
    height: 200px;
    width: 100%;
    background-color: #fff;
  }

  .mmp-usergallery-photodetails {
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;
  }

  .mmp-photo-preview-container {
    min-height: 220px !important;
  }

  .mmp-usergallery-largemap-desktoponly {
    display: none !important;
  }

  .mmp-gallery-profile-avatar {
    width: 55px !important;
    height: 55px !important;
    border-radius: 27.5px !important;
    border: solid 2px #428bca;
    cursor: pointer;
  }

  .mmp-gallery-profile-avatar-no-click {
    width: 55px !important;
    height: 55px !important;
    border-radius: 27.5px !important;
    border: solid 2px #428bca;
  }

  .mmp-usergallery-profile-container h2 {
    font-size: 16px;
  }

  .mmp-usergallery-profile-av {
    width: 70px !important;
    padding-top: 4px;
  }

  .mmp-usergallery-container {
    height: calc(var(--vh, 1vh) * 100 - 138px);
  }

  .mmp-usergallery-container {
    display: block;
    flex: none;
    flex-direction: unset;
    width: 100%;
  }

  .mmp-meta-gallery-profile.bio {
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 1) !important;
  }
}

@media (min-width: 1080px) and (max-width: 1600px) {
  .mmp-usergallery-largemap-desktoponly {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .mmp-usergallery-photodetails {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  .mmp-usergallery-largemap-mobileonly {
    display: none !important;
  }
}

.mmp-usergallery-photo-no-location {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 18px;
  height: 100%;
}

@media (min-width: 1600px) {
  .mmp-usergallery-largemap-desktoponly {
    display: flex;
    flex: 2;
    width: 100%;
  }

  .mmp-usergallery-photodetails {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  .mmp-usergallery-largemap-mobileonly {
    display: none !important;
  }
}

@media (min-width: 0px) and (max-width: 440px) {
  .mmp-pop-pho-container {
    width: 20%;
  }
}

@media (min-width: 440px) and (max-width: 680px) {
  .mmp-pop-pho-container {
    width: 25%;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .mmp-step-detail {
    display: none !important;
  }

  #mmp-sign-step-01,
  #mmp-sign-step-02,
  #mmp-sign-step-03,
  #mmp-sign-step-04 {
    width: 70px !important;
  }

  #mmp-sign-step-01 .icon,
  #mmp-sign-step-02 .icon,
  #mmp-sign-step-03 .icon,
  #mmp-sign-step-04 .icon {
    font-size: 1.3em !important;
  }

  .mmp-signup-form-outer .ui.steps {
    flex-direction: row !important;
  }

  .plan:hover {
    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    transform: scale(1) !important;
  }

  .mmp-signup-form-outer .ui.steps .step > .icon {
    margin: 0px !important;
  }
}

@media (min-width: 680px) and (max-width: 1304px) {
  .mmp-pop-pho-container {
    width: 16.666%;
  }
}

@media (min-width: 1000px) and (max-width: 1304px) {
  .mmp-pop-pho-container {
    width: 12.5%;
  }
}

@media (min-width: 1304px) and (max-width: 1695px) {
  .mmp-pop-pho-container {
    width: 10%;
  }
}

@media (min-width: 1695px) {
  .mmp-pop-pho-container {
    width: 7.692%;
  }
}

#mmp-sign-upgradedfully .icon {
  font-size: 4em !important;
  color: darkgreen !important;
  margin: 20px auto;
}

.mmp-loading-photo-preview {
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.mmp-photo-preview-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #000;
  width: 100%;
  min-height: 480px;
}

.mmp-text-content-padding {
  padding: 4px;
  padding-bottom: 0px;
}

.mmp-photo-tag {
  padding: 4px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  margin-bottom: 2px !important;
}

.mmp-hidden-share-link {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.mmp-nearby-photos-list {
  padding-top: 15px;
}

.mmp-visuallysimilar-photo-in-list {
  display: block;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.mmp-nearby-photo-in-list {
  display: block;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 80px;
  height: 98px;
  cursor: pointer;
}

.mmp-nearby-photo-in-list img,
.mmp-visuallysimilar-photo-in-list img {
  width: 80px;
  height: 80px;
}

.mmp-nearby-photo-distance {
  font-size: 11px;
  background-color: #000;
  color: #ccc;
  width: 100%;
  text-align: center;
  padding-right: 4px;
  margin-top: -5px;
}

.mmp-white-txt {
  color: #fff;
}

.mmp-background-black {
  background-color: #000;
}

.mmp-padding-vertical {
  padding-top: 7px;
  padding-bottom: 7px;
}

.mmp-padding-horizontal {
  padding-left: 7px;
  padding-right: 7px;
}

.mmp-margin-horizontal {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

#mmp-desktop-segment {
  padding: 0px;
}

.mmp-usergallery-profile-container {
  min-height: 120px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}

.mmp-squad-screen-container {
  min-height: 120px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}

.mmp-usergallery-profile-container-nobg {
  background-color: transparent;
}

.mmp-gallery-profile-avatar {
  width: 95px;
  height: 95px;
  border-radius: 47.5px;
  border: solid 2px #428bca;
  cursor: pointer;
}

.mmp-gallery-profile-avatar-no-click {
  width: 95px;
  height: 95px;
  border-radius: 47.5px;
  border: solid 2px #428bca;
}

.mmp-meta-gallery-profile {
  color: rgba(0, 0, 0, 0.7) !important;
}

.mmp-usergallery-profile-container h2 {
  margin-bottom: 0px;
}

.mmp-usergallery-profile-av {
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mmp-usergallery-profile-dt {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.mmp-nearby-photo-showmap {
  width: 80px;
  height: 80px;
}

.mmp-join-btn {
  position: relative;
  font-size: 10px !important;
  min-width: 62px;
  background-color: #428bca !important;
  color: #ffffff !important;
  padding: 4px !important;
}

.mmp-following-btn {
  margin-left: 8px !important;
  top: 6px;
  position: relative;
  font-size: 10px !important;
  min-width: 62px;
  background-color: #428bca !important;
  color: #ffffff !important;
  padding: 4px !important;
}

.mmp-poke-btn {
  margin-left: 0px !important;
  top: 6px;
  position: relative;
  font-size: 10px !important;
  min-width: 56px;
  background-color: #428bca !important;
  color: #ffffff !important;
  padding: 4px !important;
}

.mmp-poke-btn i {
  margin: 0 0.42857143em 0 -0.21428571em !important;
}

.mmp-awards-btn {
  margin-left: 0px !important;
  top: 6px;
  position: relative;
  font-size: 10px !important;
  min-width: 60px;
  background-color: #428bca !important;
  color: #ffffff !important;
  padding: 4px !important;
}

.mmp-awards-btn i {
  margin: 0 0.42857143em 0 -0.21428571em !important;
}

.mmp-tag-button {
  font-size: 0.71428571rem !important;
}

.mmp-content-container {
  padding-top: 20px !important;
}

.mmp-no-photos {
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100 - 140px);
}

.mmp-no-photos-txt {
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mmp-no-photos-txt p {
  max-width: 256px;
  text-align: center;
}

.mmp-usergallery-profile-container .mmp-loading-more {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mmp-gallery-pop-photos-bg {
  z-index: 0;
  position: absolute;
  top: 14px;
  overflow: hidden;
  height: 220px;
  width: 100%;
}

.mmp-pop-pho-container {
  padding: 0px;
  margin: 0px;
  display: block;
  float: left;
}

.mmp-pop-pho-container img {
  display: block;
}

.mmp-white-background {
  background-color: #ffffff;
  height: 100%;
}

.mmp-no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/***************************
        SIGN UP PLANS
***************************/

.ui.pricepoint.attached.secondary.segment.feature {
  background: #faf9fa;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}

.ui.pricepoint.bold.attached.secondary.segment.feature {
  font-weight: bold;
}

#PlanSelector .ui.price.attached.segment.feature {
  height: 95px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

#SignUpRootContainer a.step:hover {
  text-decoration: none;
}

#PlanSelector .ui.inverted.black.segment {
  border-color: #428bca !important;
  background-color: #428bca !important;
}

#PlanSelector .ui.inverted.attached.segment {
  border-color: #428bca !important;
  background-color: #428bca !important;
  height: 95px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#PlanSelector .ui.segments.plan {
  border: solid lightgray 1px;
  border-radius: 5px;
}

.plan-title .ui.header {
  font-size: 25px;
  line-height: 25px;
  margin: 0px;
  padding: 0px;
}

#PlanSelector {
  margin-top: 30px;
}

.plan-title {
  font-size: 25px;
  text-align: center;
}

.feature {
  border: none !important;
  font-size: 20px;
  text-align: center;
}

.feature .regular-price {
  position: absolute;
  top: 5px;
  right: 49%;
}

.btn-plan {
  padding: 20px !important;
}

.plan {
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.plan:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1;
}

.plan-ribbon {
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  background-color: #21ba45;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 55px;
  min-width: 200px;
  text-align: center;
  position: absolute;
  top: 0px;
  right: -45px;
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transform: rotate(45deg);
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0 0.4);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0 0.4);
  box-shadow: 1px 1px 4px rgba(0, 0, 0 0.4);
  z-index: 1;
  line-height: 10px;
}

.plan-ribbon.red {
  background-color: #db2828;
}

.plan-ribbon.green {
  background-color: #21ba45;
}

.plan-ribbon.yellow {
  background-color: #fbbd08;
}

.amount {
  font-size: 45px;
  line-height: 45px;
  font-weight: 600;
}

.regular-price {
  text-decoration: line-through;
  font-weight: 100;
  font-size: 16px;
  color: rgb(190, 190, 190);
}

.mmp-signup-form-outer {
  padding-top: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.mmp-signup-card {
  padding: 20px !important;
  margin-top: 20px !important;
}

.mmp-cc-button {
  float: right;
  margin-top: 25px !important;
}

.mmp-cc-ele {
  margin-top: 10px;
}

.mmp-cc-coupon {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 1px 1px 3px 1px #e6ebf1;
  transition: box-shadow 150ms ease;
  display: block;
  width: 100%;
  margin-top: 10px;
}

.mmp-cc-ele {
  box-shadow: 1px 1px 3px 1px #e6ebf1;
}

.mmp-cc-group {
  margin-bottom: 25px;
}

.mmp-signup-grid {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 110px !important;
}

#PlanSelector.ui.three.columns.stackable.grid {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mmp-profile-signup-form {
  width: 715px !important;
  min-height: 300px !important;
}

.mmp-profile-signup-form .mmp-list-passwd-errors {
  margin: 0px !important;
  height: 38px;
}

.mmp-profile-signup-form .mmp-list-passwd-errors .mmp-passwd-success {
  padding: 0.4233em 0.833em;
}

.mmp-change-invite-btn {
  margin-left: 20px !important;
}

.mmp-signup-set-field {
  margin-left: 9px !important;
}

.mmp-loading-invite-div {
  height: 100px;
}

.mmp-profile-card .mmp-profile-card-av-link {
  width: 80px !important;
  height: 80px !important;
}

.mmp-profile-card .mmp-profile-card-av-link img {
  width: 80px !important;
  height: 80px !important;
}

.mmp-pop-pho {
  width: 100%;
  height: auto;
  margin-top: -1px;
}

.mmp-top-blue-menu-container {
  width: 100% !important;
}

.mmp-menu-logo {
  margin-left: 15px;
  width: 70px;
  height: 70px;
}

.mmp-editphoto-container .active.item {
  background-color: #ffffff !important;
}

.mmp-editphoto-container .item {
  background-color: #d1d1d1 !important;
}

#mmp-editor-tabs .item {
  border-radius: 6px 6px 0px 0px !important;
}

.mmp-edit-photo-details-tab,
.mmp-adjust-photo-tab {
  padding-right: 0px !important;
}

.mmp-edit-photo-details-tab {
  display: flex;
  flex: 1;
  padding-right: 10px;
  flex-direction: row;
}

.mmp-photo-edit-preview-side {
  width: 160px;
}

.mmp-photo-edit-form-controls {
  flex: 1;
  padding-right: 0px;
}

.mmp-photo-editor-modal .mmp-edit-photo-details-tab,
.mmp-adjust-photo-tab {
  color: black !important;
}

.mmp-adjust-photo-error {
  display: flex;
  flex: 1;
  height: 521px;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: darkred;
}

@media (max-width: 460px) {
  .mmp-photo-edit-preview-side {
    display: none;
  }

  .item.mmp-mobile-menu-icon.mmp-no-select {
    padding-right: 5px !important;
  }

  .mmp-menu-logo {
    margin-left: 0px;
  }

  .mmp-main-sq-logo {
    margin-right: 0px;
  }
}

@media (max-width: 550px) {
  .mmp-settings-label {
    display: none;
  }

  .mmp-profile-settingsdropdown .dropdown.icon {
    margin: 0px !important;
  }

  .mmp-profile-settingsdropdown {
    width: 40px;
  }
}

.mmp-photo-edit-form-controls .DayPickerInput {
  width: 100%;
}

.mmp-photo-edit-form-controls-inner {
  height: 470px;
  overflow-y: scroll;
  overflow-x: hidden !important;
  padding-right: 10px;
}

#mmp-editor-tabs .ui.bottom.attached.segment.active.tab {
  padding-right: 0px;
}

.mmp-photo-to-adjust {
  width: auto;
  height: auto;
}

.mmp-photo-to-adjust .ReactCrop__image {
  max-width: 460px;
  max-height: 460px;
}

.mmp-photo-to-adjust-side {
  width: auto;
  height: 400px;
}

.photo-adjuster {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.mmp-photo-to-adjust-bg {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #ccc;
}

.checkered3 {
  background-color: #fff;
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1)
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1)
    );
}

.photo-adjustment-controls {
  width: 200px;
}

.photo-adjustment-controls {
  padding-left: 10px;
  padding-right: 20px;
}

.mmp-photo-adjuster {
  padding-bottom: 10px;
}

.mmp-photo-adjuster-segment {
  padding: 0px !important;
}

.mmp-photo-editing-btn-controls {
  padding-top: 15px;
  padding-right: 10px;
}

.mmp-image-editing-actn-btn {
  left: 5px !important;
  margin-bottom: 7px !important;
}

.mmp-image-editing-actn-prv {
  margin-bottom: 20px !important;
}

.mmp-image-editing-prvy-label {
  margin-left: 6px;
  padding-bottom: 4px;
}

.mmp-set-privacy {
  margin-bottom: 20px;
}

@media (max-width: 870px) {
  .photo-adjuster,
  .mmp-edit-photo-details-tab {
    display: block;
  }

  .photo-adjustment-controls,
  .mmp-photo-edit-preview-side {
    width: 100%;
  }

  .mmp-photo-edit-preview-side {
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }

  .mmp-photo-to-adjust-bg {
    height: 100%;
  }
}

@media (min-width: 800px) and (max-width: 870px) {
  .mmp-photo-to-adjust .ReactCrop__image {
    max-width: 340px;
    max-height: 340px;
  }

  .mmp-photo-to-adjust-bg {
    height: 300px;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .mmp-photo-to-adjust .ReactCrop__image {
    max-width: 340px;
    max-height: 340px;
  }

  .mmp-photo-to-adjust-bg {
    height: 300px;
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  .mmp-photo-to-adjust .ReactCrop__image {
    max-width: 200px;
    max-height: 200px;
  }

  .mmp-photo-to-adjust-bg {
    height: 210px;
  }
}

.mmp-remember-me {
  text-align: left !important;
  display: block !important;
}

.mmp-profile-heading-settings {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.mmp-profile-settingsdropdown {
  font-size: 14px;
}

.mmp-password-complexity-container {
  position: relative;
  left: -10px;
  height: 55px;
}

.mmp-delete-newsfeeditem {
}

.mmp-delete-btn {
  cursor: pointer;
}

.mmp-likes-popover {
  display: block;
  max-height: 250px;
  overflow-y: auto;
  width: 300px;
}

.mmp-tab-menu-icon img {
  width: 24px;
  height: 24px;
  top: 2px;
  position: relative;
}

.mmp-media-content-outer-container {
  padding: 0px 15px 0px 15px;
}

.mmp-media-content-container {
  width: 100%;
  height: 300px;
}

@media (min-width: 550px) {
  .mmp-usergallery-menu .ui.menu .item .mmp-tab-menu-label {
    display: unset;
  }

  .mmp-usergallery-menu .ui.menu .item .mmp-tab-menu-icon {
    display: none;
  }
}

@media (max-width: 550px) {
  .mmp-usergallery-menu .ui.secondary.pointing.menu .item {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.mmp-usergallery-menu .ui.secondary.pointing.menu .item {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.mmp-usergallery-menu {
  z-index: 1;
  position: relative;
  background-color: #ffffff;
}

.mmp-usergallery-menu .ui.menu {
  margin: 0px !important;
  background: #000 !important;
}

.mmp-usergallery-menu .ui.menu .item {
  color: #fff !important;
}

.mmp-usergallery-menu .ui.menu .active.item {
  border-color: #fff !important;
}

.mmp-usergallery-menu .ui.segment {
  padding: 0px !important;
}

.mmp-usergallery-menu .ui.grid {
  margin-top: 0px !important;
}

.mmp-usergallery-menu .segment {
  border: 0px !important;
}

.mmp-usergallery-menu .mmp-userprofile-newsfeed,
.mmp-usergallery-menu .mmp-user-gallery {
  min-height: calc(var(--vh, 1vh) * 100 - 200px);
}

.mmp-user-gallery .mmp-photos-scroller {
  padding-left: 4px;
  padding-top: 4px;
}

.mmp-userprofile-newsfeed {
  background-color: #f7f7f7;
}

.mmp-usergallery-profile-usernamefollow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mmp-static-follow-btn {
  min-height: 35px;
  padding-top: 2px;
  padding-right: 4px;
}

.mmp-tabs-loading .ui.pointing.secondary.menu {
  color: transparent !important;
  background-color: transparent !important;
  border-bottom: transparent !important;
}

.mmp-userprofile-newsfeed .ui.grid {
  position: relative;
  top: -10px;
}

.mmp-auth-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-modal-comments-photo {
  width: 100%;
  height: 50%;
}

.mmp-modal-map-comments {
  width: 100%;
  height: 100%;
}

.mmp-modal-comments-photo {
  background-color: #000;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.mmp-modal-comments-photoele {
  max-height: 70%;
  max-width: 100%;
  align-self: center;
}

.mmp-modal-comments-photoele-outer {
  max-width: 80%;
  align-self: center;
}

.mmp-modal-comments-photoele-outer .mmp-white-txt {
  font-size: 11px !important;
}

.mmp-modal-comments-photoele-outer h4 {
  font-size: 14px !important;
}

.mmp-repost-contaner {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.mmp-repost-newsfeed-item h3 {
  padding: 10px;
}

.mmp-repost-newsfeed-item {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.mmp-repost-newsfeed-item-comment {
  height: 100px;
}

@media (min-width: 0px) and (max-width: 730px) {
  .mmp-comments-modal #mmp-modal-map {
    display: none;
    height: 0px;
  }

  .mmp-modal-comments-photoele-outer {
    max-height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mmp-comments-container {
    flex-direction: column;
  }

  .mmp-modal-comments-photoele {
    max-width: unset;
  }

  .mmp-photo-details-contaner {
    width: 100% !important;
  }
}

@media (min-width: 730px) {
  .mmp-photo-details-contaner {
    min-width: 350px !important;
  }
}

.mmp-photo-details-contaner {
  flex: 0;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.mmp-photo-comments-contaner {
  flex: 1;
}

#mmp-modal-comments .mmp-photo-preview-container {
  min-height: 220px !important;
}

.mmp-photo-width-details-container {
  flex: 0;
}

.mmp-photo-map-container {
  flex: 1;
}

.mmp-photo-details-container-mini {
  background-color: #000;
}

.mmp-photo-details-container-mini img {
  width: 100px;
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.mmp-photo-loc-time {
  padding: 10px;
  padding-left: 0px;
}

@media (min-width: 0px) and (max-width: 450px) {
  .mmp-photo-details-contaner {
    display: none;
  }

  .mmp-photo-details-container-mini {
    display: flex;
  }
}

@media (min-width: 450px) {
  .mmp-photo-details-contaner {
    display: flex;
  }

  .mmp-photo-details-container-mini {
    display: none;
  }

  .mmp-photo-with-comment-control {
    flex: 1;
  }
}

.mmp-photo-comments-thumb {
  flex: 0;
  display: flex;
  align-items: center;
}

.mmp-comment-feed .content {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  margin-left: 5px !important;
}

.mmp-comment-feed .date {
  margin-top: 0px !important;
}

.mmp-comment-feed .text.extra {
  margin-top: 0px !important;
}

.mmp-comment-feed .label img {
  width: 50px !important;
  height: 50px !important;
}

.mmp-comment-feed .label {
  width: 50px !important;
}

.mmp-photo-comments-contaner {
  background-color: #f5f5f5;
  overflow-y: scroll;
  overflow-x: hidden;
  color: unset;
}

#mmp-comments-list .mmp-newsFeedOuter {
  margin-left: 10px;
  margin-right: 10px;
}

.mmp-flexed-comment {
  display: flex;
  flex-direction: row;
}

#mmp-comments-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#mmp-comments-list .mmp-loading-more .loader:before {
  border-color: rgba(0, 0, 0, 0.15) !important;
}

#mmp-comments-list .mmp-loading-more .loader:after {
  border-color: #000000 transparent transparent !important;
}

#mmp-comments-list .mmp-loading-more {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mmp-photo-with-comment-control {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  height: 100%;
}

.mmp-photo-comment-entry-field {
  height: 100px;
  padding: 10px;
  padding-top: 3px;
  background-color: #f5f5f5;
  display: flex;
}

.mmp-comment-profilepic {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  margin-right: 5px;
  overflow: hidden;
}

.mmp-login-or-signup {
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
}

.mmp-comment-entry-userdetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mmp-comment-textfield-entry {
  margin-top: 4px;
}

.mmp-comment-textarea textarea {
  padding: 5px !important;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  height: 60px !important;
}

.mmp-comment-entry-fields {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 5px;
}

.mmp-enter-comment-button {
  width: 125px;
  height: 40px;
  margin-left: 10px !important;
}

.mmp-comment-textarea {
  flex: 1;
}

.mmp-photo-comment-entry-field-inner {
  flex: 1;
}

.dummy-ele-forceparent-width {
  height: 1px;
  position: relative;
  top: -1px;
}

.mmp-profile-gallery-loader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100 - 50px);
}

.mmp-viewmap-editphotodetails {
  padding-right: 15px;
  float: right;
}

.mmp-open-source-license {
  margin-bottom: 50px;
}

.mmp-open-source-package-name {
  font-size: 25px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.mmp-open-source-package-license {
  padding-top: 10px;
}

.mmp-open-source-license-heading {
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
}

.mmp-filter-input {
  min-height: 60px;
}

.mmp-story-title {
  padding-right: 36px;
}

.mmp-centered-prev-next-buttons {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.mmp-btn-margin-left {
  margin-left: 5px !important;
}

.mmp-centered-prev-next-buttons .button {
  background: #4a5553 none !important;
  color: #ffffff !important;
}

.mmp-centered-prev-next-buttons .button:hover {
  background: #6e7775 none !important;
  color: #ffffff !important;
}

.mmp-app-collection-container {
  display: flex;
  flex: 1;
  height: calc(var(--vh, 1vh) * 100 - 50px);
  flex-direction: column;
}

.mmp-app-collection-photos-container {
  display: flex;
  flex: 2;
  flex-direction: row;
}

.mmp-app-collection-photos {
  flex: 1;
  margin: 10px;
  margin-top: 0px;
  overflow: hidden;
  overflow-y: scroll;
  padding-left: 8px;
  border-radius: 0.28571429rem;
  border-radius: 0.28571429rem;
  background: #fefcfc;
  border: 1px solid #ccc;
  border-radius: 0.28571429rem;
  height: calc(var(--vh, 1vh) * 100 - 364px);
  margin-bottom: 0px;
}

.mmp-app-collection-map {
  flex: 2;
  border-radius: 0.28571429rem;
  border-radius: 0.28571429rem;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0.28571429rem;
  overflow: hidden;
  margin-left: 0px;
  margin-right: 10px;
  height: calc(var(--vh, 1vh) * 100 - 364px);
}

.mmp-app-collection-details-meta-story {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.mmp-app-collection-details-meta {
  padding-right: 10px;
  flex: 1;
}

.mmp-app-collection-details-story {
  padding-left: 10px;
  flex: 2;
}

@media (min-width: 0px) and (max-width: 860px) {
  .mmp-app-collection-container {
    flex: 1;
    height: unset;
    min-height: 900px;
  }

  .mmp-app-collection-photos-container {
    flex: 0;
    flex-direction: column;
  }

  .mmp-app-collection-photos {
    display: block;
    max-height: 320px;
    margin-bottom: 10px;
  }

  .mmp-app-collection-map {
    display: block;
    height: 400px;
    margin-left: 10px;
    flex: unset;
  }

  .mmp-app-collection-details-meta-story {
    flex: 0;
    flex-direction: column;
  }

  .mmp-app-collection-details-story {
    padding-top: 10px;
    padding-left: 0px;
  }

  .mmp-app-collection-details-meta {
    padding-right: 0px;
  }
}

.mmp-app-wysiwyg-editor {
  flex: 1;
}

.mmp-app-wysiwyg-editor-controls .ql-container {
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  background: #fefcfc;
}

.mmp-app-wysiwyg-editor-controls .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
  overflow: hidden;
}

.mmp-app-collection-details-container {
  padding: 10px;
}

.mmp-app-collection-container .ql-container {
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  background: #fefcfc;
}

/* Snow Theme */
.mmp-app-collection-container .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
}

/* Bubble Theme */
.mmp-app-collection-container .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.28571429rem;
}

.mmp-app-collection-photos {
  min-height: 400px;
}

.mmp-app-collection-map {
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.mmp-app-collectionpin {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
}

.mmp-app-remove-photo-from-map {
  text-align: center;
}

.mmp-photo-collection-actions {
  background-color: transparent;
  position: relative;
  margin-top: 10px;
  right: -10px;
  top: -10px;
}

@media (max-height: 920px) {
  .mmp-photo-collection-actions {
    position: relative;
    top: -10px;
  }
}

.mmp-toast-with-status {
  color: #ffffff;
}

.mmp-app-collection-photos-container .mmp-uploadphotos-floating-dropbox {
  width: 100%;
  height: 92px;
  background-color: rgba(66, 139, 202, 0.8);
  position: unset;
  padding: 10px;
  align-items: center;
  z-index: 3;
  cursor: pointer;
}

.mmp-uploadphotos-floating-dropbox.mmp-app-photoupload-modal {
  width: 100%;
  height: 92px;
  background-color: rgba(66, 139, 202, 0.8);
  position: unset;
  padding: 10px;
  align-items: center;
  z-index: 3;
  cursor: pointer;
}

.mmp-full-pageloader-overlay {
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  right: 0;
  z-index: 256000;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-app-imageoverlay-container {
  position: relative;
  width: 100%;
}

.mmp-app-imageoverlay-action {
  position: absolute;
  text-align: center;
  width: 100%;
  color: white;
  opacity: 0;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.4s;
}

.mmp-app-imageoverlay-action:hover {
  opacity: 1;
  transition: opacity 0.4s;
}

.mmp-app-imageoverlay-action {
  background-color: rgba(0, 0, 0, 0.7);
}

.mmp-trek-map-and-charts {
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 767px) {
  .mmp-app-collection-container .mmp-photo-actions-uploading {
    justify-content: center;
  }

  .ui.modal > .mmp-photo-uploader-modal-content.content {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
}

.mmp-app-collection-container .mmp-photo-import-do-import {
  width: unset;
  padding-right: 5px;
}

.mmp-photo-uploader-modal-content.content {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.mmp-photo-uploader-modal-header {
  width: 100%;
}

.mmp-photo-uploader-modal-header-title {
  display: block;
  float: left;
}

.mmp-photo-uploader-modal-header-closebutton {
  display: block;
  float: right;
}

.mmp-photocollection-list-item {
  background-color: #ffffff;
  margin: 15px;
  margin-top: 0px !important;
  height: 400px;
  border: solid 1px #cfcfcf;
  border-radius: 4px;
  border-top-right-radius: 0px;
}

.mmp-photocollection-list-item-noborder-bottom {
  background-color: #ffffff;
  margin: 15px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 400px;
  border: solid 1px #cfcfcf;
  border-bottom-width: 0px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 4px;
  border-top-right-radius: 0px;
}

.mmp-photocollection-list-item-outer {
  width: 50%;
  display: block;
  float: left;
}

.mmp-video-list-item-outer {
  display: block;
  float: left;
}

.mmp-list-of-collections {
}

@media (max-width: 1480px) {
  .mmp-photocollection-list-item-outer {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 1000px) {
  .mmp-video-list-item-outer {
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1600px) {
  .mmp-video-list-item-outer {
    width: 50%;
  }
}

@media (min-width: 1600px) {
  .mmp-video-list-item-outer {
    width: 33.333%;
  }
}

.mmp-photocollection-details-and-map {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.mmp-photocollection-details {
  padding: 10px;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

.mmp-photocollection-map {
  flex: 2;
}

.mmp-trek-stat-chart {
  border: solid 1px #cecece;
  border-right: 0px;
}

.stat-chart-last {
  border-top: 0px;
}

.mmp-trek-stat-chart svg {
  width: 100% !important;
}

.mmp-photocollection-details-and-map .leaflet-pane,
.mmp-photocollection-details-and-map .leaflet-right,
.mmp-photocollection-details-and-map .leaflet-left {
  z-index: 99;
}

.mmp-photocollection-list-item-header-widgets {
  display: flex;
  justify-content: flex-end;
}

.mmp-photocollection-list-item-header {
  margin-left: 17px;
  margin-right: 15px;
  margin-top: 8px;
}

.mmp-trek-activity-type {
  color: #444444;
}

.mmp-photocollection-map {
  border-left: solid 1px #cecece;
}

@media (max-width: 500px) {
  .mmp-create-squad-desktop {
    display: none !important;
  }
  .mmp-create-squad-mobile {
    display: block !important;
  }
}

@media (min-width: 500px) {
  .mmp-create-squad-desktop {
    display: block !important;
  }
  .mmp-create-squad-mobile {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .mmp-photocollection-map {
    border-left: 0px;
  }

  .mmp-trek-stat-chart {
    border-left: 0px;
  }

  .mmp-trek-map-and-charts {
    flex: 3;
  }

  .mmp-photocollection-list-item-noborder-bottom {
    height: 520px;
  }

  .mmp-photocollection-list-item-header {
    margin-left: 2px !important;
    margin-right: 0px !important;
  }

  .mmp-streamslist .mmp-photocollection-list-item-header {
    margin-left: 2px !important;
    margin-right: 15px !important;
  }

  .mmp-photocollection-list-item-noborder-bottom {
    margin: 0px !important;
  }

  .mmp-photocollection-list-item {
    margin: 0px;
  }

  .mmp-trek-widget-outer {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mmp-trek-widget-outer {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mmp-photocollection-details-and-map {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .mmp-photocollection-details {
    padding: 10px;
    flex: 1;
  }

  .mmp-photocollection-map {
    flex: 2;
  }

  .mmp-photocollection-list-item {
    height: 450px;
  }
}

.mmp-breadcrumb {
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.mmp-create-collection {
  margin: 20px !important;
  margin-bottom: 0px !important;
  float: right;
}

.mmp-create-squad {
  margin: 0px !important;
  float: right;
}

.mmp-collections-main-heading {
  margin: 20px !important;
  margin-bottom: 0px !important;
  display: block;
  float: left;
}

.mmp-photocollection-list-item-header-title .icon {
  font-size: 0.75em !important;
  margin-left: 10px;
  color: #aaaaaa;
}

.mmp-app-photocollections-tab {
  background-color: #f7f7f7;
}

.mmp-triggered-map-popover {
  margin-bottom: 40px;
}

.mmp-photocollection-list-item-header-actions .ui.icon.button {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.mmp-add-photos-heading {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.mmp-app-collection-photos-container .mmp-app-collection-photos {
  background-color: #ffffff;
}

.mmp-collections-photo-selector {
  padding: 5px;
  width: 100%;
  height: 100%;
}

.mmp-collections-photo-selector-container img {
  margin: 0px auto;
}

.mmp-collections-photo-selector-container {
  width: 33%;
  display: block;
  float: left;
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 520px) {
  .mmp-collections-photo-selector-container {
    width: 33%;
  }
}

@media (min-width: 520px) and (max-width: 640px) {
  .mmp-collections-photo-selector-container {
    width: 25%;
  }
}

@media (min-width: 640px) and (max-width: 860px) {
  .mmp-collections-photo-selector-container {
    width: 20%;
  }
}

@media (min-width: 860px) and (max-width: 1180px) {
  .mmp-collections-photo-selector-container {
    width: 50%;
  }
}

@media (min-width: 1180px) and (max-width: 1600px) {
  .mmp-collections-photo-selector-container {
    width: 33%;
  }
}

@media (min-width: 1600px) {
  .mmp-collections-photo-selector-container {
    width: 25%;
  }
}

.mmp-photocollection-addimage i.image.outline.icon {
  margin-right: 2px !important;
}

.mmp-collections-photo-selector .ui.dimmer {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.mmp-collection-newsfeed-view .mmp-photocollection-list-item-outer {
  width: 100% !important;
}

.mmp-collection-newsfeed-view .mmp-video-list-item-outer {
  width: 100% !important;
}

.mmp-collection-newsfeed-view .mmp-photocollection-list-item,
.mmp-collection-newsfeed-view .mmp-photocollection-list-item-header {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.mmp-collection-newsfeed-view .mmp-photocollection-details {
  flex: unset;
}

.mmp-collection-newsfeed-view .mmp-photocollection-details-and-map {
  flex-direction: column;
}

.mmp-collection-newsfeed-view .mmp-photocollection-list-item {
  border-top-right-radius: 4px;
}

.mmp-collection-newsfeed-view .mmp-photocollection-list-item {
  height: unset;
}

.mmp-collection-newsfeed-view .mmp-photocollection-map {
  height: 300px !important;
  flex: unset;
}

.mmp-collection-newsfeed-view .mmp-trek-map {
  height: 300px !important;
  flex: unset;
}

.mmp-edit-photo-details-tab .mmp-photo-selector-img-preview {
  height: 180px;
}

@media (max-width: 650px) {
  .mmp-edit-photo-details-tab .mmp-photo-edit-form-controls-inner {
    height: 280px;
  }

  .mmp-edit-photo-details-tab .mmp-image-import-preview {
    width: 100px;
    height: 100px;
  }

  .mmp-edit-photo-details-tab .mmp-photo-selector-img-preview {
    height: 130px;
  }
}

.mmp-profile-meta {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: normal;
}

.mmp-app-exif-section-icon,
.mmp-app-exif-section-icon img {
  width: 60px !important;
  height: 60px !important;
}

.mmp-app-photogear-modal {
  background-color: #ffffff;
  height: calc(var(--vh, 1vh) * 100 - 160px);
  overflow-y: scroll;
  padding-left: 10px;
}

.mmp-app-photogear-modal-header {
  background-color: #ffffff;
  height: 50px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 1.28571429em;
  line-height: 1.28571429em;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.mmp-modal-heading-gear-img {
  width: 45px;
  height: 45px;
  padding-right: 10px;
}

.mmp-photo-editor-modal-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mmp-photo-with-overlay {
  overflow: hidden;
}

.mmp-usercontrols {
  width: 50px;
}

.mmp-usercontrols .mmp-profile-widget-icon {
  margin-right: 0px !important;
}

.mmp-useraccountwidget-outer .dropdown.icon {
  margin-left: 0.5em !important;
}

.mmp-ftue-icon {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.mmp-app-wantsignup-container {
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #1b1c1d;
  padding-top: 60px;
  padding-bottom: 60px;
}

.app-modal-cta-image {
  width: 128px;
  height: 128px;
  margin: 10px;
}

.mmp-app-action-buttons {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mmp-app-action-buttons button {
  width: 120px !important;
}

.mmp-row-button-margin-bottom {
  margin-bottom: 4px !important;
}

.mmp-meta-gallery-profile.social-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.mmp-meta-gallery-profile.social-buttons .mmp-static-follow-btn .ui.button {
  margin-left: 2px !important;
}

.mmp-meta-gallery-profile.social-buttons .mmp-static-follow-btn {
  padding-top: 0px !important;
}

.mmp-chat-container {
  background-color: #f5f5f5;
  max-width: 100%;
  max-height: 680px;
  height: calc(var(--vh, 1vh) * 100 - 140px);
  color: #000 !important;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mmp-chat-avatar-icon {
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px !important;
  border: solid 1px #428bca;
}

.mmp-chat-message {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 18px;
  background-color: #ffffff;
  padding: 6px;
  margin: 6px;
  align-self: flex-start;
  width: 75%;
  white-space: pre-line;
}

.mmp-own-chat-message {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 18px;
  background-color: #ffffff;
  padding: 6px;
  margin: 6px;
  align-self: flex-end;
  width: 75%;
}

.mmp-chat-message.chat-map {
  width: 95% !important;
}

.mmp-own-chat-message.chat-map {
  width: 95% !important;
}

.mmp-message-send-controls {
  display: flex;
  flex-direction: row;
  padding: 10px;
  height: 60px;
  align-items: center;
}

.mmp-message-entryfield {
  background-color: #ffffff;
  border-radius: 20px;
  height: 40px;
  flex: 1;
  display: flex;
}

.mmp-message-entryfield input {
  border: 0px;
  border-radius: 20px;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  outline-width: 0;
}

.mmp-message-addphotos-button {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
  right: 8px;
}

.mmp-message-addphotos-button img {
  width: 25px;
  height: 25px;
  align-self: center;
}

.mmp-message-send-button {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.mmp-message-send-button img {
  width: 40px;
  height: 40px;
}

#mmp-messages-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#mmp-modal-comments-scroller {
  flex: 1;
  overflow-y: scroll;
}

#mmp-modal-comments-scroller .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.15) !important;
}

#mmp-awards-scroller .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.15) !important;
}

#mmp-awards-scroller {
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 10px;
  color: #000000;
  height: 500px;
  overflow-y: scroll;
}

.mmp-emoji-selector {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.mmp-chats-dropdown-opener {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  cursor: pointer;
}

#mmp-rhs-first {
  margin-left: 0px !important;
}

@media (max-width: 767px) {
  .mmp-chats-mobile {
    display: block !important;
  }

  .mmp-chats-desktop {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mmp-chats-mobile {
    display: none !important;
  }

  .mmp-chats-desktop {
    display: block !important;
  }
}

.mmp-list-active-chats {
  margin: 0px !important;
  background-color: #ffffff;
  height: 400px;
  overflow-y: auto;
  min-width: 300px;
}

.mmp-clickable-chat {
  cursor: pointer;
}

.mmp-list-notifications {
  margin: 0px !important;
  background-color: #ffffff;
  height: 400px;
  overflow-y: auto;
  min-width: 300px;
}

.mmp-active-chats-popupouter.ui.bottom.popup::before {
  background: #ffffff !important;
}

.mmp-active-chat {
  padding: 6px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.mmp-active-chat.mmp-active-chat-bold {
  background-color: #f9ffe6;
  border: solid 1px #c6ff80;
}

.mmp-active-chats-popupouter {
  padding: 0px !important;
  background-color: #ffffff !important;
  background: #ffffff !important;
}

.mmp-chat-avatar-icon.active-chats {
  margin-right: 6px;
  border: solid 1px #ffffff;
  align-self: flex-start;
}

@media (max-width: 767px) {
  .mmp-comments-modal.ui.modal > .content {
    padding: 0px !important;
  }

  .mmp-comments-modal.ui.modal {
    margin: 0px !important;
  }
}

.mmp-message-recipient-header {
  display: flex;
  flex-direction: row;
  padding: 6px;
  height: 44px;
  background-color: #428bca !important;
  cursor: pointer;
}

.mmp-app-chat-header-name {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin-left: 10px;
}

.mmp-chat-photo-selector {
  width: 300px;
  height: 400px;
  padding: 0px;
  margin: 0px;
}

.mmp-selected-photos-slider {
  height: 110px;
  padding-top: 2px;
}

.mmp-selected-photos-slide-inner {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mmp-photos-to-select {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mmp-photos-to-select-noneselected {
  width: 100%;
  height: 364px;
  overflow-y: scroll;
}

.mmp-photo-selector-popup {
  padding: 0px;
  margin: 0px;
}

.mmp-selectable-chat-photo {
  width: 100%;
}

.mmp-selectable-chat-photo-container {
  padding: 5px;
  padding-top: 0px;
  width: 25%;
  height: auto;
  display: block;
  float: left;
  cursor: pointer;
}

.mmp-selectable-chat-photo-selected {
  width: 100%;
}

.mmp-selectable-chat-photo-selected {
  padding: 5px;
  padding-top: 0px;
  width: 73px;
  height: auto;
  display: block;
  float: left;
  cursor: pointer;
}

.mmp-photoselector-chat-close {
  float: right;
  cursor: pointer;
  position: relative;
  right: -14px;
  top: -6px;
}

.mmp-add-photos-chat-heading {
  float: left;
  cursor: pointer;
  font-weight: bold;
}

.mmp-message-addphotos-count {
  color: #ffffff;
  font-weight: bold;
  font-size: 10px;
  background-color: #db2828 !important;
  border-radius: 8px;
  position: relative;
  top: -10px;
  right: -30px;
  padding: 2px;
  min-width: 18px;
  text-align: center;
}

.mmp-message-addphotos-count-hidden {
  opacity: 0;
}

.mmp-chat-addphotos-container,
.mmp-selected-photos-slider {
  border: solid 1px lightgray;
  border-radius: 5px;
  margin-top: 5px;
}

.mmp-chat-addphotos-heading {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.mmp-chat-message-photo-thm {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  margin-left: 5px;
  margin-bottom: 5px;
}

#mmp-messages-list .mmp-chatmessage-map {
  height: 300px !important;
  width: 100% !important;
  display: block;
}

.mmp-own-chat-message .mmp-chat-message-with-map {
  width: 100% !important;
}

.mmp-chat-message .mmp-chat-message-with-map {
  width: 100% !important;
}

.lottie-heart-animation {
  width: 50px;
  height: 50px;
  padding-right: 24px;
}

.lottie-heart-animation svg {
  overflow: visible !important;
}

.lottie-heart-animation div {
  margin: 0px;
  position: relative;
  left: -12px;
}

.lottie-heart-animation img {
  margin: 0px;
  position: relative;
  left: -12px;
  margin-bottom: -4px;
}

.lottie-heart-animation .heart-clicker {
  width: 20px;
  height: 20px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  margin: -10px;
  top: 24px;
  left: 13px;
  z-index: 3;
}

.newsfeed-post-likes {
  position: relative;
  left: -20px;
}

.mmp-profile-awards {
  height: 400px;
  overflow-y: scroll;
}

.mmp-card-bottomspacer {
  height: 40px;
}

.mmp-award-picture {
  display: flex !important;
  justify-content: center;
  cursor: pointer;
}

.mmp-award-newsfeed-item .slick-dots li button:before,
.mmp-photocarousel-newsfeed-item .slick-dots li button:before {
  color: black !important;
}

.mmp-award-newsfeed-item .mmp-award-slide-heading {
  font-size: 14px;
  color: black;
  margin-bottom: 6px;
}

.mmp-award-newsfeed-item .mmp-award-slide-points {
  font-size: 14px;
  color: black;
}

.mmp-award-newsfeed-item .mmp-award-picture img {
  width: 120px;
  height: 120px;
  animation: none;
}

.mmp-photocarousel-newsfeed-item {
  width: 100%;
  padding-left: 10px;
  padding-bottom: 10px;
}

.mmp-award-newsfeed-item {
  width: 100%;
  height: 260px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.mmp-award-newsfeed-item .slick-prev:before,
.mmp-award-newsfeed-item .slick-next:before,
.mmp-photocarousel-newsfeed-item .slick-prev:before,
.mmp-photocarousel-newsfeed-item .slick-next:before {
  color: black;
}

.slick-dots li button:before {
  color: white !important;
}

.mmp-award-slide-heading {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.mmp-award-slide-title {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  text-align: center;
}

.mmp-award-slide-points {
  font-size: 18px;
  color: white;
}

.mmp-award-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#mmp-awards-modal > .content {
  padding: 0px !important;
}

#mmp-awards-modal {
  max-width: 360px !important;
  left: unset !important;
  margin: unset !important;
}

#lottie-stars svg {
  overflow: visible !important;
}

#mmp-awards-modal .close.icon {
  color: white !important;
  margin-top: -45px;
}

#mmp-awards-modal .slick-track {
  margin: 0 auto;
}

.mmp-award-newsfeed-item .slick-track {
  min-width: 100%;
}

.mmp-photocarousel-newsfeed-item .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-achived-awards .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 230px;
}

.mmp-meta-gallery-profile a,
.mmp-meta-gallery-profile a:visited,
.mmp-meta-gallery-profile a:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

#mmp-achievements-modal .ui.modal > .content {
  max-width: 360px;
  justify-content: "center";
}

#mmp-achievements-modal .content {
  padding: 0px !important;
}

.mmp-profile-awards {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mmp-profile-awards-content {
  padding: 0px !important;
  padding-top: 10px;
}

.mmp-award-slide-heading {
  text-align: center;
}

@media (max-width: 767px) {
  #mmp-achievements-modal .ui.modal > .content {
    padding: 0px !important;
  }
}

@media (max-width: 750px) {
  .mmp-award-slide-title {
    display: none;
  }
  .mmp-award-newsfeed-item {
    height: 180px;
  }
  .mmp-award-newsfeed-item.mmp-achived-awards .slick-slider {
    height: 180px;
  }
  .mmp-achived-awards .slick-track {
    min-height: 180px;
  }
}

.mmp-achievements-menu {
  z-index: 1;
  position: relative;
  background-color: #ffffff;
}

.mmp-achievements-menu .ui.menu {
  margin: 0px !important;
  background: #ffffff !important;
}

.mmp-achievements-menu .ui.menu .item {
  color: rgba(0, 0, 0, 0.85) !important;
}

.mmp-achievements-menu .ui.menu .active.item {
  border-color: rgba(0, 0, 0, 0.85) !important;
}

.mmp-achievements-menu .ui.segment {
  padding: 0px !important;
}

.mmp-achievements-menu .ui.grid {
  margin-top: 0px !important;
}

.mmp-achievements-menu .segment {
  border: 0px !important;
}

.bio-userprofilegallery {
  max-width: 400px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mmp-chat-img-icon {
  width: 25px;
  height: 25px;
}

.mmp-notifications-unread-alert {
  width: 18px !important;
  height: 18px !important;
  background-color: #db2828 !important;
  position: relative;
  left: 5px;
  top: -11px;
  margin-left: -18px;
  border-radius: 9px;
  box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.7); /* Safari, older Chrome */
  -moz-box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.7); /* Older Firefox */
}

.mmp-notifications-unread {
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
}

.mmp-notifications-unread-plus {
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  margin-left: 2px;
}

.mmp-no-results-found-chat-popup {
  flex: 1;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.mmp-photo-import-privacy-newsfeed label {
  font-size: 16px !important;
  color: #fff !important;
}

.mmp-photo-import-privacy-newsfeed {
  display: flex;
  flex-direction: row;
}

.mmp-photo-import-privacy-newsfeed form {
  padding-left: 20px;
}

#mmp-achievements-modal {
  min-height: 520px;
}

.mmp-profile-backgroundimages {
  position: absolute;
}

.mmp-profile-backgroundimages .mmp-profile-background-image {
  width: 60px;
  height: 60px;
  padding: 0px;
  margin: 0px;
  margin-top: -4px;
}

.mmp-profile-backgroundimages-opacity {
  background-color: "white";
  overflow: hidden;
}

.mmp-profile-segement {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  margin: 0;
  padding: 0;
  border-radius: 0.28571429rem;
  overflow: hidden;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.mmp-profile-segement .mmp-gallery-pop-photos-bg {
  top: 0px;
}

.mmp-profile-segement .mmp-pop-pho-container {
  width: 16.6666667%;
}

.mmp-margin-horizontal-noleft {
  margin-left: 0px !important;
  margin-right: 4px !important;
}

.mmp-stat-label {
  margin-right: 8px !important;
  font-size: 10px !important;
  color: #ffffff !important;
  padding: 4px !important;
}

.mmp-profile-segement .mmp-usergallery-profile-container {
  padding: 6px !important;
  padding-left: 0px !important;
}

.mmp-profile-segement a,
.mmp-profile-segement-for-detail a {
  color: rgba(0, 0, 0, 0.7) !important;
}

.mmp-story-title .tippy-box,
.mmp-newsFeedAvatar .tippy-box {
  background-color: #ffffff !important;
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.mmp-story-title .tippy-content,
.mmp-newsFeedAvatar .tippy-content {
  padding: 0px !important;
}

.mmp-profile-underline {
  text-decoration: underline !important;
  cursor: pointer;
}

.mmp-profile-card a,
.mmp-profile-card a:hover {
  color: rgba(0, 0, 0, 0.7);
}

.mmp-profile-card .mmp-profile-subtitle a,
.mmp-profile-card .mmp-profile-subtitle a:hover {
  color: rgba(0, 0, 0, 0.5);
}

.mmp-photo-modal .mmp-profile-card a,
.mmp-photo-modal .mmp-profile-card a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.mmp-photo-modal .mmp-profile-card .mmp-profile-subtitle a,
.mmp-photo-modal .mmp-profile-card .mmp-profile-subtitle a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.mmp-invite-friends-image {
  width: 180px;
}

.mmp-invite-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mmp-notif-row {
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-color: #00000011;
  border-bottom-style: solid;
}

.mmp-list-bordered-top {
  border-top-width: 1px;
  border-top-color: #00000011;
  border-top-style: solid;
}

.mmp-notif-inner-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.mmp-notif-inner-inner-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}

.mmp-notif-icon-container {
  margin-right: 4px;
}

.mmp-notif-avatar-container {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  margin-right: 10px;
}

.mmp-clickable-notif-avatar {
  cursor: pointer;
}

.mmp-list-active-chats p,
.mmp-list-notifications p {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.mmp-notif-avatar {
  width: 50px;
  height: 50px;
}

.group-message-avatar {
  background-color: #428bca;
  padding: 1.5px;
  border: solid 2px #ffffff;
}

.mmp-notif-image-container {
  margin-bottom: -18px;
}

.mmp-notif-thumbnail {
  width: 22px;
  height: 22px;
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 11px;
  position: relative;
  bottom: 18px;
  left: 34px;
}

.mmp-notif-info-row {
  display: "flex";
  flex: 1;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.mmp-notif-message {
  color: #000000;
  margin-bottom: 2px;
}

.mmp-notif-date {
  font-size: 10px;
  color: #00000055;
  line-height: 16px;
}

.mmp-read-receipt-container {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-read-receipt-indicator {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #428bca;
  cursor: pointer;
}

.bottom-sheet-wrapper {
  -webkit-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -moz-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -ms-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -o-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 998;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.bottom-sheet-wrapper .bottom-sheet-block-layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.bottom-sheet-wrapper .bottom-sheet {
  -webkit-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -moz-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -ms-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -o-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -webkit-transform: translate3d(0px, 100%, 0);
  -moz-transform: translate3d(0px, 100%, 0);
  -ms-transform: translate3d(0px, 100%, 0);
  -o-transform: translate3d(0px, 100%, 0);
  transform: translate3d(0px, 100%, 0);
  position: absolute;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  background-color: #fff;
}

.bottom-sheet-wrapper.hide {
  display: none;
}

.bottom-sheet-wrapper .bottom-sheet-item {
  display: block;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 20px 0;
  border: none;
  border-top: 1px solid #ededed;
  font-size: 16px;
}

.bottom-sheet-wrapper .bottom-sheet-item.cancel {
  color: #d0021b;
}

.bottom-sheet-wrapper .toast {
  bottom: 110%;
}

.bottom-sheet-wrapper.enter {
  opacity: 1;
}

.bottom-sheet-wrapper.enter .bottom-sheet {
  -webkit-transform: translate3d(0px, 0px, 0);
  -moz-transform: translate3d(0px, 0px, 0);
  -ms-transform: translate3d(0px, 0px, 0);
  -o-transform: translate3d(0px, 0px, 0);
  transform: translate3d(0px, 0px, 0);
}

.bottom-sheet-item.cancel {
  display: none;
}

.mmp-app-download-layout {
  height: 200px;
  background-color: rgba(66, 139, 202, 1);
  color: #000;
}

.mmp-app-download-layout .mmp-app-preview {
  height: 190px;
  padding-left: 10px;
}

.mmp-app-download-layout .mmp-app-stores-info {
  padding-right: 10px;
}

.mmp-app-download-layout .mmp-app-stores-info p {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.app-store-grad {
  background: rgb(255, 255, 255);
  background: -moz-radial-gradient(
    farthest-corner at 10px 100px,
    rgba(255, 255, 255, 1) 0%,
    rgba(66, 139, 202, 1) 100%
  );
  background: -webkit-radial-gradient(
    farthest-corner at 10px 100px,
    rgba(255, 255, 255, 1) 0%,
    rgba(66, 139, 202, 1) 100%
  );
  background: radial-gradient(
    farthest-corner at 10px 100px,
    rgba(255, 255, 255, 1) 0%,
    rgba(66, 139, 202, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#428bca", GradientType=1);
  width: 100%;
  padding: 0px;
}

.mmp-enjoy-app {
  text-align: center;
}

.mmp-download-app-button {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  padding: 10px;
}

.mmp-app-download-layout .mmp-app-icon {
  height: 40px !important;
}

.mmp-app-download-close {
  position: absolute;
  top: 0px;
  right: 0px;
}

.mmp-app-download-close img {
  width: 26px;
  height: 26px;
  margin: 4px;
  opacity: 0.4;
  cursor: pointer;
}

.mmp-notification-segment {
  padding: 10px;
  font-weight: bold;
}

.mmp-notif-icon {
  width: 40px;
  height: 40px;
}

.mmp-upgrade-warning {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.mmp-app-settings-modal {
  min-height: 300px;
}

.mmp-trek-widget-stat {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 10px;
}

.mmp-trek-widget-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.mmp-trek-widget-panel {
  min-height: 50px;
}

.mmp-trek-widget-panel-label {
  font-weight: bold;
}

.mmp-trek-widget-panel-value {
  opacity: 0.7;
}

.mmp-title-trek-stats {
  margin-top: 20px;
}

.mmp-title-trek-stats-padded {
  margin: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.mmp-trek-widget-outer {
  background-color: #f9f9f9;
  margin-left: 15px;
  margin-right: 15px;
  padding: 20px;
  border-top-width: 0px !important;
  border: solid 1px #cfcfcf;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  min-height: 242px;
}

.mmp-trek-widget-outer .mmp-trek-widget-stat {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mmp-photocollection-list-item {
  margin-bottom: 0px !important;
}

#mmp-trek-detail-modal .content {
  padding: 0px !important;
}

#mmp-collection-detail-modal {
}

#mmp-collection-detail-modal .content {
  padding: 0px !important;
}

.mmp-web-button {
  border: solid 1px rgba(66, 139, 202, 1);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  color: rgba(66, 139, 202, 1);
  cursor: pointer;
}

.mmp-trek-data-tooltip {
  background-color: #ffffff;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

.mmp-top-filters-bar .ui.search .results,
.mmp-map-modal .ui.search .results {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

.dimmer {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.margin-top-33px {
  margin-top: -33px;
}

.map-thumbnail-image {
  z-index: 500;
  cursor: pointer !important;
  position: relative;
  top: 8px;
}

.map-top-38px {
  top: 38px !important;
  cursor: pointer !important;
}

.profile-top-38px {
  top: 38px !important;
  cursor: pointer !important;
  height: 30px;
}

.relative-top-8px {
  position: relative;
  top: 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-image {
  animation: fadeIn 1s;
}

.mmp-map-popup-button {
  cursor: pointer;
  text-decoration: underline;
}

.mmp-popup-map {
  min-width: 300px !important;
  min-height: 300px !important;
  background-color: #ffffff;
  z-index: 2;
}

@media (max-width: 400px) {
  .mmp-popup-map {
    min-width: 200px !important;
    min-height: 200px !important;
  }
}

.mmp-waslive-video {
  background-color: #000000;
}

.mmp-waslive-video-container {
  width: 100%;
  height: 600px;
}

@media (max-width: 800px) {
  .mmp-waslive-video-container {
    width: 100%;
    height: 300px;
  }
}

.mmp-published-video {
  background-color: #000000;
}

.mmp-app-collections-action-buttons {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mmp-social-login-buttons {
  padding-top: 20px;
}

.facebook-login {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}

.google-login {
  background-color: #4285f4 !important;
  color: #ffffff !important;
}

.google-login:hover {
  background-color: #0d60ea !important;
}

.google-login > div:nth-child(1) > div:nth-child(1) {
  background-color: #ffffff !important;
  padding: 2px;
}

.mmp-signup-button-zindex {
  z-index: 100;
}

.mmp-signup-button-row {
  display: flex;
  flex-direction: row;
  width: 640px;
}

@media (max-width: 752px) {
  .mmp-signup-button-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
#mmp-stream-detail-modal .content {
  padding: 0px !important;
  margin: 0px !important;
}

#mmp-stream-detail-modal .content > div {
  padding: 10px !important;
}
#mmp-video-detail-modal .content {
  padding: 0px !important;
  margin: 0px !important;
}

#mmp-video-detail-modal .content > div {
  padding: 10px !important;
}

.mmp-button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.mmp-map-popup-trigger-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.mmp-photo-modal-info .linkify,
.mmp-photo-modal-info .facebook,
.mmp-photo-modal-info .twitter,
.mmp-photo-modal-info .heart {
  margin: 0px !important;
  font-size: 14px;
}

.mmp-photo-modal-share-list {
  margin-right: 2px !important;
  margin-left: 3px !important;
}

.mmp-photo-modal-share-list {
  white-space: nowrap;
}

.mmp-app-photo-action-buttons {
  display: flex;
  flex-direction: row;
}

.mmp-newsfeed-actions {
  display: flex;
  flex-direction: row;
}

.mmp-group-privacy-label {
  position: relative;
  bottom: 3px;
}

.hidden-squad-map {
  opacity: 0;
}

.visible-squad-map {
  opacity: 1;
}

.mmp-squad-members-container {
  background-color: #f7f7f7;
}

.mmp-squad-videos-container {
  background-color: #f7f7f7;
}

.mmp-squad-photos-container {
  background-color: #f7f7f7;
  min-height: calc(var(--vh, 1vh) * 100);
}

.mmp-group-admin-badge {
  width: 36px;
  height: 36px;
}

.mmp-group-member-action-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.mmp-group-member-action-button {
  margin-bottom: 4px;
}

.mmp-groups-admin-section {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.mmp-groups-admin-txt {
  opacity: 0.6;
}

@media (max-width: 990px) {
  .mmp-profiles-listing .column {
    padding-top: 0px !important;
  }
}

/* START map preview image popup */
.leaflet-popup-close-button {
  display: none;
}
.leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0px !important;
  width: unset;
}

.leaflet-popup-content-wrapper {
  border-radius: unset;
  background: black;
}

.leaflet-popup-content-wrapper {
  width: 106px;
  height: 106px;
}
.mmp-map-tooltip-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 2px;
}
.leaflet-popup-content {
  width: unset;
}
.leaflet-popup-tip {
  background: black;
}
.mmp-map-tooltip-avatar-bg {
  background: black;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  top: -10px;
  left: -10px;
  position: absolute;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.mmp-map-tooltip-avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: block;
  top: -7px;
  left: -7px;
  position: absolute;
}
.mmp-map-tooltip-preview {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.popup-with-action button {
  position: absolute;
  top: -2px;
  left: -2px;
  color: #fff !important;
  background-color: transparent !important;
}

/* END map preview image popup */

.mmp-app-checkbox-subtitle {
  opacity: 0.5;
}
.mmp-app-subtitle-disabled {
  opacity: 0.2;
}
.mmp-app-checkbox-subtitle-row .icon {
  font-size: 16px;
}
.mmp-app-finalise-photo-settings .field {
  margin-bottom: 0px !important;
}
.mmp-app-finalise-photo-groups {
  padding-left: 38px;
}
.mmp-photo-like-disabled {
  opacity: 0.4;
}
.mmp-photo-like-btn {
  margin-right: 1.4px;
}
.mmp-open-source-package-section {
  margin-bottom: 12px;
}
.mmp-rememberme-hint {
  text-align: left;
  margin-top: 10px;
  opacity: 0.7;
  color: orangered;
}

.vjs-big-play-button {
  display: none !important;
}

.mmp-mini-profile-widget-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mmp-no-results-to-show {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-background {
  background-color: #13293c;
  min-height: calc(var(--vh, 1vh) * 100);
}

.app-store-icon,
.app-slides-outer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-slide img {
  height: 500px;
  width: 231px;
  border-radius: 10px;
  filter: drop-shadow(3px 3px 5px #000000);
  cursor: default;
}

.app-slide {
  display: flex !important;
  justify-content: center;
  cursor: pointer;
}

.app-slides-container {
  max-width: 900px;
  width: 100%;
}

.app-app-icons {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-app-icons div {
  z-index: 1 !important;
}

.app-slides-container .slick-list {
  padding-top: 45px;
  padding-bottom: 75px;
}

.app-store-icon .ui.horizontal.inverted.link.list {
  z-index: 2 !important;
}

#mmp-desktop-segment {
  border-bottom: 0px;
}

.photos-thumb-container {
  padding-top: 5px;
  padding-left: 1px;
}

.app-more-photos {
  width: 75px;
  height: 75px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: solid 1px #dedede;
  color: #bbb;
}

.google-login,
.facebook-login {
  z-index: 100;
}

@media (min-width: 0px) and (max-width: 550px) {
  .mmp-usergallery-menu .ui.menu .item .mmp-tab-menu-label {
    display: none;
  }

  .mmp-usergallery-menu .ui.menu .item .mmp-tab-menu-icon {
    display: unset;
  }

  .ui.secondary.pointing.menu .item {
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .mmp-photocollection-list-item-outer .mmp-media-content-container {
    padding: 0px !important;
  }

  .mmp-photocollection-list-item-outer .mmp-photocollection-list-item-header {
    margin-right: 0px !important;
  }

  .mmp-video-list-item-outer .mmp-media-content-container,
  .mmp-media-content-outer-container {
    padding: 0px !important;
  }

  .mmp-video-list-item-outer .mmp-photocollection-list-item-header {
    margin-right: 0px !important;
  }
}

.mmp-award-newsfeed-item .slick-dots li,
.mmp-photocarousel-newsfeed-item .slick-dots li {
  width: 4px;
}

.mmp-photos-carousel-preview img {
  width: auto;
  max-width: 100%;
  max-height: 440px;
}

.mmp-photos-carousel-preview-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mmp-photos-carousel-preview-container .mmp-newsfeed-private-photo {
  top: 5px;
  position: relative;
  right: 22px;
}

.mmp-photos-carousel-photo-img {
  cursor: pointer;
}

.mmp-profiles-carousel-preview-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 140px !important;
}

.mmp-profiles-carousel-photo-img {
  cursor: pointer;
}

.mmp-followed-members {
  height: 160px !important;
}

.mmp-profiles-carousel-preview-container a {
  margin-top: 6px;
}

.mmp-update-profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  flex: 1;
}

.mmp-updated-profile-pic-img {
  width: 90px;
  height: 90px;
  border-radius: 45px;
}

.mmp-share-feed-button {
  z-index: 100;
  position: absolute;
  top: 4px;
  right: 4px;
}

.mmp-share-feed-buttons i {
  margin: 0px !important;
}

.mmp-share-feed-button .dropdown {
  margin: 0px !important;
}

.mmp-share-feed-button-dropdown {
  background-color: white !important;
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.mmp-share-feed-button-video {
  position: relative;
  float: right;
  z-index: 1;
  padding: 6px;
}

.app-video-play-icon {
  width: 40px;
  height: 40px;
  opacity: 0.7;
}

.mmp-video-container {
  position: relative;
}
.mmp-video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  opacity: 0.7;
  z-index: 1;
  cursor: pointer;
}

.mmp-shared-link {
  cursor: pointer;
}
.mmp-shared-link-image {
  width: 100%;
  height: "auto";
}
.mmp-shared-link-detail {
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  padding-top: 8px;
}
.mmp-shared-link-title {
  font-size: 16px;
  font-weight: bold;
}
.mmp-shared-link-description {
  font-size: 12px;
}

.slick-next {
  right: -20px;
}

.slick-prev {
  left: -20px;
}

#mmp-app-modals > div {
  opacity: 0; /* Default opacity for all modals */
}

#mmp-app-modals > div:last-child {
  opacity: 1; /* Opacity for the last modal */
}

.mmp-chat-message-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  width: 100%;
}

.mmp-chat-map-flexed-container {
  flex: 1;
}

.mmp-emoji-reactions {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  background-color: #eeeeee;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 18px;
}

.mmp-reaction-select {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 22px;
}

.mmp-reaction-select-row {
  display: flex;
  flex-direction: row;
}

.mmp-emoji-reactions-done {
  display: flex;
  flex-direction: row;
}

.mmp-emoji-reaction-count {
  font-size: 12px;
}

.mmp-emoji-reaction-emoji-clickable {
  cursor: pointer !important;
}

.mmp-emoji-reaction-emoji-clickable-opacity {
  cursor: pointer !important;
  opacity: 0.3;
  padding: 10px;
  margin: -10px;
}

.mmp-emoji-with-count {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  align-items: center;
}

.mmp-emojis-detail-trigger {
  display: flex;
  cursor: pointer !important;
}

.mmp-emoji-time-ago {
  font-size: 12px;
  opacity: 0.4;
  line-height: 10px;
  white-space: nowrap;
  padding-left: 10px;
}

.mmp-emoji-with-name-emoji {
  font-size: 23px;
  padding-right: 6px;
}

.mmp-emoji-with-name {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.mmp-emoji-detail {
  margin-bottom: 8px;
  display: flex;
  flex-direction: "row";
  align-items: center;
}

.mmp-emoji-detail div {
  white-space: nowrap;
}

.mmp-emoji-detail:last-of-type {
  margin-bottom: 0px;
}

.mmp-emoji-detail-popup.ui.top.right.popup::before {
  opacity: 0;
}

.mmp-emoji-detail-popup.ui.top.left.popup::before {
  opacity: 0;
}

.mmp-emoji-detail-popup {
  min-width: 160px !important;
  border-radius: 30px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.mmp-emoji-selector-popup {
  min-width: 160px !important;
  border-radius: 30px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.mmp-share-link-preview-image {
  width: 100%;
  height: auto;
}

.mmp-loading-more-container {
  background-color: #cecece;
  padding: 80px;
}

.mmp-generate-ai-image-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.mmp-generate-ai-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmp-generate-ai-image {
  max-height: 200px;
  width: auto;
}

.mmp-lang-flag {
  color: hsla(0, 0%, 100%, 1);
  font-size: 20px;
}

.mmp-lang-button:focus {
  background-color: #fff !important;
}

.mmp-lang-button {
  margin-right: 0.5em !important;
}

.mmp-lang-menu-scrolling {
  overflow-y: scroll !important;
  height: 300px;
}
.mmp-lang-menu-scrolling-outer {
  min-height: 300px !important;
  margin-top: 6px !important;
}

.mmp-lang-menu-scrolling-outer .item {
  color: rgba(0, 0, 0, 0.7) !important;
}

.mmp-lang-menu-scrolling-outer .mmp-lang-flag {
  padding-right: 8px;
}

.mmp-lang-menu-scrolling > .item:hover {
  background: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.95) !important;
  cursor: pointer;
}

.mmp-list-notifications-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
  margin-left: 14px;
  margin-right: 14px;
}

.mmp-list-notifications-clear-button {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #428bca;
  cursor: pointer;
}

.mmp-app-checkbox-subtitle-row {
  padding-left: 26px;
  padding-bottom: 8px;
  padding-top: 2px;
  display: flex;
  flex-direction: row;
}
