.app-menu-header {
  width: 100%;
  height: 64px;
  background-color: #428bca !important;
  -webkit-box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
}
.app-menu-image {
  height: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.app-menu-container {
  margin: 0px auto;
}

@media (min-width: 970px) {
  .app-menu-container {
    width: 750px;
  }
}
@media (min-width: 1200px) {
  .app-menu-container {
    width: 970px;
  }
}
@media (min-width: 1300px) {
  .app-menu-container {
    width: 1200px;
  }
}
.app-main-body {
  padding-top: 72px;
}
